<template>
    <vs-popup class="hourAdd_popup" title="Ajouter crénaux horaire" :active.sync="popupActive">
        <template v-if="loading==false">
            <vs-row v-if="inf10==true">
                <span style="color:red"> Les minutes doivent être superieur à 10, ou égale à 0.</span>
            </vs-row>
            <vs-row>
                <vs-col vs-w="6">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Premier crénaux" v-model="debut" type="time"/>
                    </div>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Durée de séance" v-model="espace" type="time"/>
                    </div>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Fin du dernier crénaux" v-model="fin" type="time"/>
                    </div>
                    <!-- <br>
                    <div class="vx-col w-full">
                        <label style=" font-size:11px; padding-left:5px; "> Coach </label>
                        <v-select v-model="coach" label="Coach" :options="coachList"  />
                    </div> -->
                </vs-col>

                <vs-col vs-w="6">
                    <ul class="container mt-4 ml-4">
                        <li>
                            <vs-checkbox v-model="lundi" class="mb-3 pt-1">Lundi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="mardi" class="mb-4 pt-1">Mardi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="mercredi" class="mb-4 pt-1">Mercredi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="jeudi" class="mb-4 pt-1">Jeudi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="vendredi" class="mb-4 pt-1">Vendredi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="samedi" class="mb-4 pt-1">Samedi</vs-checkbox>
                        </li>
                        <li>
                            <vs-checkbox v-model="dimanche" class="mb-4 pt-1">Dimanche</vs-checkbox>
                        </li>

                    </ul>
                </vs-col>
            </vs-row>

            <br/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="createCrenaux()" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
                </vs-col>
            </vs-row>
        </template>
        <template v-else>
            <h2>Chargement ...</h2>
        </template>
    </vs-popup>
</template>

<style lang="scss">
/*.hourAdd_popup
{

}*/
</style>

<script>

import BookingHour from '@/database/models/booking_hour'
// import Member from '@/database/models/member'
import vSelect from 'vue-select'

export default {
    components:{
        'v-select': vSelect,
	},
    props: {
        typeM:'',
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            machine:null,
            loading:false,

            debut:'08:00',
            espace:'00:30',
            fin:'20:00',

            inf10:false,

            lundi    : 0,
            mardi    : 0,
            mercredi : 0,
            jeudi    : 0,
            vendredi : 0,
            samedi   : 0,
            dimanche : 0,

            group:false,
            // coach:null,
            // coachList:[],
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
       
    },
    mounted:function(){
        
    },
    methods:{
       
        openPopup( machine, callback )
        {
           

            this.callback    = null
            this.popupActive = true

            this.machine = machine;
            this.loading = false;

            this.lundi    = 0;
            this.mardi    = 0;
            this.mercredi = 0;
            this.jeudi    = 0;
            this.vendredi = 0;
            this.samedi   = 0;
            this.dimanche = 0;
            // this.coach    = "Aucun Coach";

            if(callback)
                this.callback = callback
        },
        add(){

            this.popupActive = false
        },


        async createCrenaux()
        {
            let d = this.espace.split(":");
            
            
            if ((  parseInt(d[1])==0) ||  (  parseInt(d[1])>=10) ){
                this.inf10=false;
                this.loading = true;
                //création des plages horaires
                let tab_hour = [];
                let time_debut = (new Date("2010-01-01 "+this.debut+':00')).getTime();
                let time_fin = (new Date("2010-01-01 "+this.fin+':00')).getTime();

                let time_ref = (new Date("2010-01-01 00:00:00")).getTime();
                let time_esp = (new Date("2010-01-01 "+this.espace+':00')).getTime();
                time_esp = time_esp-time_ref;

                let foncHumainDate = function( timestamp )
                {
                    let d = new Date(timestamp);
                    let h = d.getHours();
                    let m = d.getMinutes();
                    if(h<10) h = '0'+h;
                    if(m<10) m = '0'+m;
                    return h+':'+m;
                };
                
                

                if( this.typeM=="g")
                {
                    this.group=true;
                    do
                    {
                        
                        // if(foncHumainDate(time_debut)!=this.fin){
                            tab_hour.push({
                                machine  : this.machine,
                                hour     :  foncHumainDate(time_debut),
                                duration : this.espace,
                                flag     : 0,
                                
                                //todo coach
                            });
                            
                            time_debut += time_esp;
                        // }
                    }while( time_debut <= time_fin );
                    // loic < -> <=
                    
                }
                else{
                    do
                    {
                        tab_hour.push({
                            machine  : this.machine,
                            hour     :  foncHumainDate(time_debut),
                            duration : this.espace,
                            flag     : 0,
                            
                            //todo coach
                        });
                        time_debut += time_esp;
                    }while( time_debut <= time_fin );
                }
                
                //Si c'est un groupe il ne peux y avoir que une seul heure
                /*if(machine.type != 'm' )
                {
                    var buffer = [];
                    buffer.push( tab_hour[0]);
                    tab_hour = buffer;
                }*/

                //Ajout hour si il y en a 
                if (tab_hour.length == 0)
                    return;

                let ok = false;
                if(this.lundi)
                {
                    ok = true;
                    await AddDay( 1, tab_hour );
                }
                if(this.mardi)
                {
                    ok = true;
                    await AddDay( 2, tab_hour );
                }
                if(this.mercredi)
                {
                    ok = true;
                    await AddDay( 3, tab_hour );
                }
                if(this.jeudi)
                {
                    ok = true;
                    await AddDay( 4, tab_hour );
                }
                if(this.vendredi)
                {
                    ok = true;
                    await AddDay( 5, tab_hour );
                }
                if(this.samedi)
                {
                    ok = true;
                    await AddDay( 6, tab_hour );
                }
                if(this.dimanche)
                {
                    ok = true;
                    await AddDay( 0, tab_hour );
                }

                this.loading = false;
                if(ok==false)
                    return alert("Veuillez choisir un jour/horaire");
                    

                this.popupActive = false;
                this.callback();
            }
            else{
                this.inf10=true;
            }
        }
    }
}

async function AddDay( day, tab )
{
  
    return new Promise( async (resolve)=>
    {
        for( var i=0; i<tab.length; i++)
        {
            
           
            //Special gestion du flag
            let flag = tab[i].flag;
            if( (tab.length -1 ) == i)
                flag = "e";
            
            
            
            await BookingHour.add(
            {
                machine  : tab[i].machine,
                hour     : tab[i].hour,
                duration : tab[i].duration,
                flag     : flag,
                day      : day,
                
            })
        }
        resolve();
    })
}

</script>