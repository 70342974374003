<template>
  <div>
    <vs-button color="success" type="filled" v-on:click="openPopup" class="">Ajouter imbrication</vs-button>
    <vs-popup class="lock_popup" title="Imbrication machine" :active.sync="popupActive">
      <p v-if="machines.length === 0" class="center">Aucune machine à imbriquer</p>
      <vs-row v-else>
        <vs-select v-model="selected.machine" class="w-full select-large" label="Machine à imbriquer">
          <vs-select-item v-for="m in filteredMachines" :value="m" :text="m.name" :key="m.uid" />
        </vs-select>
        <vs-select v-model="selected.type" class="w-full select-large mt-5" label="Type d'imbrication">
          <vs-select-item value="b" text="Blocage machine" class="w-full" />
          <vs-select-item value="p" text="Réduction places dispos" class="w-full" />
        </vs-select>
        <div v-if="selected.machine && selected.type" class="w-full">
          <p class="my-3">Quand <b>{{ name }}</b> est réservée alors <b>{{ selected.machine.name }} 
            {{ selected.type === 'b'? 'est bloquée' : 'perd 1 place dispo' }}</b>.</p>
          <vs-checkbox v-model="mirrorlock" class="my-4">Imbriquer en mirroir</vs-checkbox>
          <div v-if="mirrorlock">
            <vs-select v-model="mirrored.type" class="w-full select-large mt-3" label="Type d'imbrication">
              <vs-select-item value="b" text="Blocage machine" class="w-full" />
              <vs-select-item value="p" text="Réduction places dispos" class="w-full" />
            </vs-select>
            <p class="my-3">Quand <b>{{ selected.machine.name }}</b> est réservée alors <b>{{ name }} 
            {{ mirrored.type === 'b'? 'est bloquée' : 'perd 1 place dispo' }}</b>.</p>
          </div>
        </div>
      </vs-row>
      <vs-row v-if="selected.machine && selected.type" class="mt-5">
        <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
          <div></div>
          <vs-button color="success" type="filled" v-on:click="machineLock" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<style lang="scss"></style>

<script>
// import Machine from "@/database/models/machine";
// import MachineLock from '@/database/models/machine_lock'
import vSelect from "vue-select";

export default {
  components: {
    "v-select": vSelect,
  },
  emits: ['reloadlock'],
  props: {
    machine: {
      type: Object,
      required: true
    },
    machines: {
      type: Array,
      required: true
    },
    locked: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      popupActive: false,

      // machines: this.initMachines,
      // locked: this.initLocked,

      mirrorlock: false,

      selected: {
        machine: '',
        type: 'b'
      },
      mirrored: {
        type: 'b'
      }
    };
  },
  computed: {
    filteredMachines() {
      const filtered = this.machines
      const locked = this.locked
      for (let m of filtered) {

        const index = filtered.findIndex((m) => m.uid === this.machine.uid);
        if (index > -1) {
          filtered.splice(index, 1);
        }

        if (locked.findIndex(e => e.uid_machine_2 === m.uid) > -1) {
          const index = filtered.findIndex(e => e.uid === m.uid)
          filtered.splice(index, 1)
        }
      }
      
      filtered.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))

      return filtered
    },
    // lockedmirrorlock() {
    //   if (this.filteredMachines.findIndex(e => e.uid_machine_1 === m.uid) > -1) {
    //     return true
    //   } else return false
    // },
    name() {
      return this.machine.name
    }
  },
  methods: {
    openPopup() {
      this.popupActive = true;
      // console.log(this.machine)
    },

    generateKey(m, a) {
      return `${m}_${a}`
    },

    machineLock() {
      const lock = {
        machine: this.machine.uid,
        lockto: this.selected.machine.uid,
        type: this.selected.type,
        mirrorlock: this.mirrorlock,
        mirror: this.mirrored.type,
      }
      this.$srvApi
        .Req("post", "studio/machinelock", lock)
        .then(() => {
          this.$emit("reloadlock")
          this.popupActive = false;
        })
        .catch((err)=>{
          //console.log(err)
          alert('Erreur imbrication',err)
        })
    }

  },
};
</script>